import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *:focus {
    outline: 0;
  }
  html, body {
    height: 100%;
    width: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }

  ::-webkit-scrollbar { width: 5px; }
  
  ::-webkit-scrollbar-track { background:#36323F; }
  
  ::-webkit-scrollbar-thumb { 
    border-radius: 10px;
    background: #04D361; 
  }
`;