import React from 'react';
import { Link } from 'gatsby';

import logo from '../../../assets/images/principais/icon.png'
import * as H from './styles';

export default function Header() { 
  return (
    <H.Container>
      <H.Content>
        <nav>
          <Link to="/">
            <img src={logo} alt="Gabriel Mendes" />
          </Link>
        </nav>
        <aside>
          <H.MenuItens>
            <div>
              <Link to="#Whoiam">Quem Sou</Link>
              <Link to="#Skills">Habilidades</Link>
              {/* <Link to="#Portfolio">Projetos</Link> */}
              <Link to="#Partners">Parceiros</Link>
              <Link to="#Contact">Contato</Link>
            </div>
          </H.MenuItens>
        </aside>
      </H.Content>
    </H.Container>
  )
}
