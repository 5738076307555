import React from 'react';
import PropTypes from 'prop-types';
import GlobalStyle from '../../styles/global';

import Header from './Header';
import Footer from './Footer';

import { Main } from './styles';

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
