import React from 'react';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import * as F from './styles';

export default function Footer() {
  return (
    <>
      <F.Footer>
        <F.Text>
          Gabriel Mendes - Todos os direitos reservados © 2019 - Site desenvolvido com ❤
        </F.Text> 
        <F.Socials>
          <a href="https://www.linkedin.com/in/gabrielgoncalvesmendes/"><FaLinkedin color="#eee" size={30} /></a>
          <a href="https://github.com/GabrielGoncalvesMendes"><FaGithub color="#eee" size={30} /></a>
        </F.Socials>
      </F.Footer>
    </>
  )
}
