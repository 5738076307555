import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  display: block;
  width: 100%; 
  background-color: #36323F;
  padding: 0 30px;
`;

export const Content = styled.div`
  height: 64px;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  nav {
    display: flex;
    align-items: center;
    a {
      font-weight: bold;
      color: #fff;
      img {
        width: 52px;
        height: 52px;
        border-radius: 50%;
      }
    }
  }
  aside {
    display: flex;
    align-items: center;
  }
`;

export const MenuItens = styled.div`
  @media only screen and (max-width: 640px) {
    display: flex;
    margin-left: 20px;
    border-left: 1px solid #eee;
    div {
      display: flex;
      text-align: right;
      margin-right: 10px;
      a {
        padding-left: 10px;
        display: block;
        margin-top: 2px;
        font-size: 18px;
        color: #E0EAFA;
      }
    }
  }

  @media only screen and (min-width: 640px) {
    display: flex;
    margin-left: 20px;
    border-left: 1px solid #eee;
    div {
      display: flex;
      text-align: right;
      margin-right: 10px;
      a {
        padding-left: 10px;
        display: block;
        margin-top: 2px;
        font-size: 18px;
        color: #E0EAFA;
      }
    }
  }
`;