import styled from 'styled-components';

export const Footer = styled.footer`
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #36323F;
  color: #E0EAFA;
`;

export const Text = styled.p`
  text-align: center;
  font-size: 0.875rem;
  color: inherit;
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  a {
    margin-left: 20%;
    margin-right: 20%;
  }
`;
